<script>
    import './DataObject.ImportTemplate.ts';
</script>
<template>
       <span @click="openFileUpload" v-bind="$attrs">
         <slot>{{$t('Import')}} {{$t('template')}}</slot>
                <input  accept="'xlsx'" type="file" ref="fileInput" class="d-none" @change="fileInputChange" />
                <UploadProgress  ref="uploadProgress"  :dataObject = "dataObject" :openDataLog = "openDataLog" :batch = "batch" :hideDataLog="hideDataLog"></UploadProgress>

       </span>
     
</template>
<script setup>
    import {ref} from 'vue';
    import UploadProgress from "./Components.UploadProgress.vue";
    const uploadProgress = ref(null);

    const props = defineProps(['dataObject','beforeImport','batch', 'openDataLog', 'autoClose','hideDataLog']);
    const fileInput = ref(null);
    const emit = defineEmits(['completed','error']);
    
     const openFileUpload=()=>{
        fileInput.value.click();
    }
    const fileInputChange =()=>{
        const vOptions = getOptions();
        vOptions.file = fileInput.value.files[0];
        uploadProgress.value.show();
        props.dataObject.importTemplate.importData(vOptions);
    }

    
    const getOptions = ()=>{
        return {
            beforeImport:props.beforeImport,
            onCompleted:uploadCompleted,
            onError:uploadError,
            batch:props.batch != undefined && props.batch !== false?true:false
        }
    }

    const uploadCompleted= (pProgress)=>{
        emit("completed",pProgress);
        fileInput.value.value = "";
        if(props.autoClose){
            uploadProgress.value.dismiss();
        }

    }
    const uploadError= (pError)=>{
        emit("error",pError)
        uploadProgress.value.dismiss();
        fileInput.value.value = "";

    }
   

</script>